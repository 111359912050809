export const PROJECT_TYPE_CLOUD_SUPPORT = "Cloud Support";
export const PROJECT_TYPE_CLOUD_EVALUATION_SUPPORT = "Cloud Evaluation Support";

export const CASE_CLOSED_STATE = "Closed";
export const CASE_OPEN_STATE = "Open";

export const CONSUMED_QUERY_HOURS = 'Consumed Query Hours';
export const REAMAINING_QUERY_HOURS = 'Remaining Query Hours';
export const HOURS = 'Hours';

export const CASE_TYPE_INCIDENT = 'Incident';
export const CASE_TYPE_QUERY = 'Query';
export const CASE_TYPE_ENGAGEMENT = 'Engagement';

export const COLOURS = [
  "#1F84A6", 
  "#25AAE1", 
  "#3EB5E5", 
  "#57C0E9",
  "#70CBED", 
  "#89D6F1", 
  "#A2E1F5", 
  
];
